import React, { useEffect, useRef, useState } from 'react';
import { Howl, Howler } from 'howler';

declare var window: any;

interface Props {
    src: string;
    onReady: (p: VisualPlayer) => void;
}
const Player = ({ src, onReady }: Props) => {
    const audioRef = useRef<Howl>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const player = useRef<VisualPlayer>();
    const [audioReady, setAudioReady] = useState(false);

    useEffect(() => {
        audioRef.current = new Howl({
            src,
            autoplay: false,
            loop: true,
            html5: true,
            onloaderror() {
                console.log("error loading :(");
            },
            onplayerror() {
                console.log("error playing :(");
            },
            onload() {
                setAudioReady(true);
            }
        });
    }, [src]);

    useEffect(() => {
        if (!audioReady || !audioRef.current || !canvasRef.current) {
            return;
        }
        player.current = visualPlayer(canvasRef.current, audioRef.current);
        onReady(player.current);

        return () => {
            if (!player.current) {
                return;
            }
            player.current.stop();
            player.current = undefined;
        }
    }, [audioReady, audioRef.current, canvasRef.current]);

    return (
        <React.Fragment>
            <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
        </React.Fragment>
    );
};

export interface VisualPlayer {
    start: () => void;
    stop: () => void;
    seek: (s: number) => void;
}
function visualPlayer(canvasEl: any, audio: Howl): VisualPlayer {
    let started = false;
    const q = window;
    const anim = q.requestAnimationFrame || q.webkitRequestAnimationFrame ||
        q.mozRequestAnimationFrame || q.oRequestAnimationFrame ||
        q.msRequestAnimationFrame || function (f: any) { q.setTimeout(f, 16); };
    const ctx = canvasEl.getContext('2d');
    const pi = Math.PI;
    const pi2 = Math.PI * 2;
    const circles: any[] = [];
    let fd: any[] | Uint8Array, analyser: any;
    let t = 0;
    let paused = true;
    let w = canvasEl.offsetWidth, h = canvasEl.offsetHeight;
    let audioReady = false;

    const COLORS = {
        main: new Color({
            r: 210, g: 159, b: 212, a: 0.07,
        }),
        hit: new Color({
            r: 230, g: 112, b: 235, a: 0.3,
        }),
        high: new Color({
            r: 195, g: 170, b: 195, a: 0.4,
        }),
        tinkle: new Color({
            r: 230, g: 159, b: 212, a: 0.5,
        }),
    }

    function resize() {
        w = q.innerWidth;
        h = q.innerHeight;
        canvasEl.width = w;
        canvasEl.height = h;
        maxBarHeight = w / 2;
        barWidth = (h - pad * 2) / fdlen;
    }
    q.addEventListener('resize', resize);

    function start() {
        resize();
        paused = false;
        if (!started) {
            loop();
            started = true;
        }
        audio.play();
    }
    function stop() {
        paused = true;
        if (audioReady) audio.pause();
    }
    function seek(s: number) {
        if (audioReady) {
            audio.seek(s);
            if (!audio.playing) {
                audio.play();
                started = true;
            }
        }
    }


    class Circle {
        immortal: boolean;
        wavy: boolean = false;
        r: number;
        ac: number = 0;
        ghost: boolean = false;
        dx: number;
        dy: number;
        x: number;
        y: number;
        color: Color;
        gr: number;
        dead: boolean;
        customUpdate?: () => void;

        constructor(x: number, y: number, r: number, gr: number, color: Color) {
            this.dx = 0;
            this.dy = 0;
            this.x = x;
            this.y = y;
            this.r = r;
            this.color = new Color(color); // copy color
            this.gr = gr || 0;
            this.dead = false;
            this.immortal = false;
        }

        update = () => {
            if (this.dead) return;

            this.x += this.dx;
            this.y += this.dy;
            if (this.gr && this.r) this.r += this.gr;
            if (this.r < 0) {
                this.r = 1;
                this.gr = 0;
                this.dead = !this.immortal;
            }
            if (this.ac && this.color.a) {
                this.color.a += this.ac;
            }
            if (this.color.a < 0) {
                this.color.a = 0;
                this.ac = 0;
                this.dead = !this.immortal;
            }
            if (this.x < 0 || this.y < 0 || this.x - this.r > w || this.y - this.r > h) {
                this.dead = !this.immortal;
            }
            if (this.customUpdate) this.customUpdate();
        };
    }

    const beatCircleR = () => Math.min(w, h) * 0.8;
    const beatCircle = new Circle(0, 0, beatCircleR(), -beatCircleR() * 0.3, COLORS.main);
    beatCircle.immortal = true;
    beatCircle.wavy = true;
    circles.push(beatCircle);

    const fdlen = 256;
    const maxVal = 255;
    let maxBarHeight = 0;
    const pad = 0;
    let barWidth = 0;
    const rules = [
        {
            from: Math.floor(0),
            to: Math.floor(fdlen * 0.02),
            threshold: Math.floor(maxVal * 0.95),
            action: 'pulselow',
            cooldown: 10,
            lastHit: 0,
            t: 0,
        },
        {
            from: Math.floor(fdlen * 0.7),
            to: Math.floor(fdlen),
            threshold: Math.floor(maxVal * 0.7),
            action: 'pulsehigh',
            cooldown: 10,
            lastHit: 0,
            t: 0,
        },
        {
            from: Math.floor(fdlen * 0.8),
            to: Math.floor(fdlen),
            threshold: Math.floor(maxVal * 0.01),
            action: 'tinkle',
            cooldown: 50,
            lastHit: 0,
            t: 0,
        },
    ];

    function executeRule(r: typeof rules[0]) {
        let total;
        r.t = r.t || 0;
        r.t++;
        r.lastHit = t;

        switch (r.action) {
            case 'pulselow':
                beatCircle.r = beatCircleR();
                const hit = new Circle(0, h, beatCircle.r, 2, COLORS.hit);
                hit.ac = -0.03;
                hit.wavy = true;
                circles.push(hit);
                break;
            case 'pulsehigh':
                total = 6;
                for (let i = 0; i < total; i++) {
                    const hi = new Circle(
                        w / 2 + 200 * Math.cos(pi2 * i / total),
                        h / 2 + 200 * Math.sin(pi2 * i / total),
                        beatCircle.r,
                        2,
                        COLORS.high
                    );
                    hi.ac = -0.01;
                    hi.ghost = true;
                    hi.dx = (r.t % 2 === 0 ? 20 : 5) * Math.cos(pi2 * i / total) * 0.1;
                    hi.dy = (r.t % 2 === 0 ? 5 : 20) * Math.sin(pi2 * i / total) * 0.1;
                    hi.wavy = true;
                    circles.push(hi);
                }
                break;
            default:
                break;
        }
    }

    const waveColor = new Color({
        r: 240, g: 120, b: 230, a: 0.2,
    });
    const lineColor = new Color({
        r: 255, g: 100, b: 200, a: 0.4,
    });

    function update() {
        t++;

        rules.forEach(r => {
            if (r.lastHit && r.lastHit + r.cooldown > t) return;

            for (let i = r.from; i < r.to; i++) {
                const val = fd[i];
                if (val >= r.threshold) {
                    executeRule(r);
                    break;
                }
            }
        });

        for (let i = circles.length - 1; i >= 0; i--) {
            circles[i].update();
            if (circles[i].dead) circles.splice(i, 1);
        }
    }

    function render() {
        ctx.clearRect(0, 0, w, h);
        const points: any[] = [];

        // render audio wave
        const waveCnt = 4;
        for (let i = 0; i <= fdlen; i++) {
            const val = fd[i];
            const waves = [];
            for (let j = 0; j < waveCnt; j++) {
                const mod = j / waveCnt * 0.6;
                waves.push(mod + val / maxVal * mod);
            }
            points.push({
                r: waves,
                m: pi / 2 + pi * i / fdlen
            });
        }

        const oldPoints = [];
        for (let i = 0; i < fdlen; i++) {
            const val = fd[i];
            const barHeight = val / maxVal * maxBarHeight;
            ctx.beginPath();
            ctx.fillStyle = waveColor.str();
            ctx.rect(
                w / 2 + w / 2 - barHeight,
                i * barWidth + pad,
                barHeight,
                barWidth
            );
            ctx.fill();

            const pheight = barHeight * (beatCircle.r / beatCircleR()) * 0.85;
            oldPoints.push({
                x: w / 2 + w / 2 - pheight,
                y: i * barWidth - barWidth / 2 + pad,
                barHeight: pheight,
            })
        }

        for (let i = 1; i < oldPoints.length; i++) {
            let p = oldPoints[i];
            let prev = oldPoints[i - 1];
            ctx.beginPath();
            ctx.strokeStyle = lineColor.str();
            ctx.moveTo(p.x, p.y);
            ctx.lineTo(prev.x, prev.y);
            ctx.stroke();
        }
        for (let i = 1; i < oldPoints.length; i++) {
            let p = oldPoints[i];
            let prev = oldPoints[i - 1];
            ctx.beginPath();
            ctx.strokeStyle = lineColor.str();
            ctx.moveTo(p.x + p.barHeight, p.y + barWidth);
            ctx.lineTo(prev.x + prev.barHeight, prev.y + barWidth);
            ctx.stroke();
        }

        // render circles
        circles.forEach(c => {
            if (c.wavy) {
                // draw waves
                for (let j = 0; j < waveCnt; j++) {
                    let p = points[0];
                    const r = c.r * p.r[j];
                    ctx.beginPath();
                    ctx.moveTo(c.x + r * Math.cos(p.m), c.y + r * Math.sin(p.m));

                    for (let i = 1; i < points.length; i++) {
                        let p = points[i];
                        const r = c.r * p.r[j];
                        ctx.lineTo(c.x + r * Math.cos(p.m), c.y + r * Math.sin(p.m));
                    }
                    for (let i = points.length - 1; i >= 0; i--) {
                        let p = points[i];
                        const r = c.r * p.r[j];
                        ctx.lineTo(c.x - r * Math.cos(p.m), c.y + r * Math.sin(p.m));
                    }

                }
            } else {
                ctx.beginPath();
                ctx.arc(c.x, c.y, c.r, 0, pi2);
            }
            ctx[c.ghost ? 'strokeStyle' : 'fillStyle'] = c.color.str();
            ctx[c.ghost ? 'stroke' : 'fill']();
        });
    }

    function loop() {
        if (!paused) {
            analyser.getByteFrequencyData(fd);
            update();
            render();
        }
        anim(loop);
    }

    const result = {
        start,
        stop,
        seek,
    }

    analyser = Howler.ctx.createAnalyser();
    window.fuck = audio;
    try {
        const node = audio._sounds[0]._node;
        const src = Howler.ctx.createMediaElementSource(node);
        src.connect(Howler.masterGain);
        Howler.masterGain.connect(analyser);
        analyser.connect(Howler.ctx.destination);
    } catch (err) {
        console.log("fuck web audio / howler / html5 / apple / everything", err);
    }
    fd = new Uint8Array(analyser.frequencyBinCount);
    audioReady = true;

    return result;
}

class Color {
    r: number;
    g: number;
    b: number;
    a: number;
    constructor({ r, g, b, a }: { r: number, g: number, b: number, a: number }) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }
    str = () => {
        return 'rgba(' + this.r + ',' + this.g + ', ' + this.b + ',' + this.a + ')';
    };
}

export default Player;