import React, { Fragment } from 'react';
import { Appear, Link, Button, Project, Words, Loading, Paragraph, Row, Col, Line } from 'arwes';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Player, { VisualPlayer } from '../components/player';

function secsToMinSecs(timeInSeconds: number) {
    const pad = function (num: number, size: number) { return ('000' + num).slice(size * -1); };
    const time = timeInSeconds.toFixed(3);
    const minutes = Math.floor(parseInt(time) / 60) % 60;
    const seconds = Math.floor(parseInt(time) - minutes * 60);

    return pad(minutes, 2) + ':' + pad(seconds, 2);
}
function Index() {
    const [ready, setReady] = React.useState(false);
    const [consent, setConsent] = React.useState<boolean | undefined>(undefined);
    const [showTracks, setShowTracks] = React.useState(false);
    const player = React.useRef<VisualPlayer | undefined>();

    function onReady(p: VisualPlayer) {
        player.current = p;
        setTimeout(() => setReady(true), 1000);
    }

    function seekTo(time: number) {
        if (!player.current) {
            return;
        }
        player.current.seek(time);
    }

    function onConsent() {
        setConsent(true);
        player.current?.start();
        setTimeout(() => setShowTracks(true), 1000);
    }

    const trackList = [
        { time: 4, name: "Levitate" },
        { time: 3 * 60 + 23, name: "Magik Shopp" },
        { time: 5 * 60 + 8, name: "Onnet Under Attack" },
        { time: 6 * 60 + 7, name: "Night Underground" },
        { time: 8 * 60 + 11, name: "SYLPH" },
        { time: 13 * 60, name: "Parriña" },
        { time: 15 * 60 + 43, name: "Purayeh" },
        { time: 17 * 60 + 38, name: "Sitting Apocalypsis" },
        { time: 20 * 60 + 2, name: "Enigma Mt." },
        { time: 22 * 60 + 52, name: "Terra V2" },
        { time: 26 * 60 + 2, name: "Lapsus Jingle" },
    ];

    return (
        <Layout>
            <Helmet>
                <title>glitchy.website</title>
            </Helmet>

            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, overflow: "hidden", pointerEvents: "none" }}>
                <Player src="/sounds/mixtunes.mp3" onReady={onReady} />
            </div>

            {consent === undefined && (
                !ready ? (
                    <Project key={1} animate layer="primary" header="glitchy.website" style={{ width: "100%" }}>
                        {(anim: any) => (
                            <Fragment>
                                <Paragraph><Words animate show={anim.entered}>Willkommen.</Words></Paragraph>
                                <Paragraph><Words animate show={anim.entered}>Initializing Human Interface...</Words></Paragraph>
                                <Loading animate />
                            </Fragment>
                        )}
                    </Project>
                ) : (
                        <Project key={2} animate layer="primary" header="glitchy.website" style={{ width: "100%" }}>
                            {(anim: any) => (
                                <Fragment>
                                    <Paragraph><Words animate show={anim.entered}>Willkommen.</Words></Paragraph>
                                    <Paragraph><Words animate show={anim.entered}>Intercept Incoming Transmission?</Words></Paragraph>
                                    <Row style={{ marginTop: "2rem" }}>
                                        <Col s={6}><Button style={{ width: "100%" }} buttonProps={{ style: { width: "100%", textAlign: "center" } }} animate show={anim.entered} onClick={onConsent}>Yes</Button></Col>
                                        <Col s={6}><Button style={{ width: "100%" }} buttonProps={{ style: { width: "100%", textAlign: "center" } }} animate show={anim.entered} layer="alert" onClick={() => setConsent(false)}>No</Button></Col>
                                    </Row>
                                </Fragment>
                            )}
                        </Project>
                    )
            )}

            {consent && (
                <Project key={3} animate layer="primary" header="glitchy.website beatdown" style={{ width: "100%" }}>
                    {(anim: any) => (
                        <div style={{ minHeight: "80vh" }}>
                            {!ready && (
                                <div>
                                    <Paragraph><Words animate show={anim.entered}>Transmission Intercepted.</Words></Paragraph>
                                    <Paragraph><Words animate show={anim.entered}>Commencing Playback...</Words></Paragraph>
                                    <Loading animate />
                                </div>
                            )}
                            {ready && (
                                <Appear animate show={showTracks} style={{ width: "100%" }}>
                                    <table style={{ width: "100%" }}>
                                        {trackList.map(track => (
                                            <tbody key={track.name}>
                                                <tr>
                                                    <td><Button style={{ width: "100%" }} buttonProps={{ style: { width: "100%", textAlign: "center" } }} layer="control" onClick={() => seekTo(track.time)}>{secsToMinSecs(track.time)}</Button></td>
                                                    <td style={{ textAlign: "right", fontSize: ".9rem" }}><Words animate show={anim.entered}>{track.name}</Words></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td style={{ paddingLeft: (2 + Math.round(Math.random() * 4)) + "rem" }}><Line animate show={anim.entered} /></td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Appear>
                            )}
                        </div>
                    )}
                </Project>
            )}

            {consent === false && (
                <Project key={4} animate layer="primary" header="glitchy.website exit" style={{ width: "100%" }}>
                    {(anim: any) => (
                        <Fragment>
                            <Paragraph>
                                <Words animate show={anim.entered}>Understandable. Have a great rest of your day.</Words>
                            </Paragraph>

                            <Button style={{ width: "100%", marginTop: "2rem", marginBottom: "1rem" }} buttonProps={{ style: { width: "100%", textAlign: "center" } }} layer="alert" animate onClick={() => setConsent(undefined)}>Re-evaluate Choice</Button>
                        </Fragment>
                    )}
                </Project>
            )}

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", fontSize: ".8rem", marginTop: "1rem" }}>
                <Link href="https://soundcloud.com/try_andy_beets"><Words animate>SoundCloud Mixes.</Words></Link>
                <Link href="/bandcamp"><Words animate>Bandcamp Releases.</Words></Link>
            </div>
        </Layout>
    );
};
export default Index;